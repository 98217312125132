
import {
  createModule,
  
} from 'yoshi-flow-bm-runtime';
const createBILogger = undefined;
import type { ModuleLite } from 'yoshi-flow-bm-runtime/common';

createModule({
  module: {
    module: {"moduleId":"MEMBERS_ACCOUNT_BM","config":{"moduleId":"MEMBERS_ACCOUNT_BM","appDefId":"09504b58-86dd-420c-9c00-c688b9419eea","isCoreService":true,"routeNamespace":"members-account-bm","translations":{"enabled":true,"suspense":true},"sentry":{"DSN":"https://87298671c2a24e0e8646c233564025b9@sentry.wixpress.com/1209","id":"87298671c2a24e0e8646c233564025b9","projectName":"members-account-bm","teamName":"mm"},"experiments":{"scopes":["members-area"]},"enabledByExperiments":["specs.infra.yoshi-bm.ChangeMe"],"topology":{"staticsUrl":{"artifactId":"com.wixpress.members-account-bm"}},"moduleBundleName":"module"},"fedopsPath":"/home/builduser/agent00/work/e0ea1f188487681d/members-area/members-account-bm/fedops.json"} as ModuleLite,
    
    
  },
  pages: [
    
  {
    component: {"componentId":"MEMBERS_ACCOUNT_BM.pages.index","componentType":"page","componentName":"MEMBERS_ACCOUNT_BM.pages.index"},
    loadReactComponent: async () => (await import(/* webpackChunkName: "MEMBERS_ACCOUNT_BM.pages.index" */ './pages/index')).default,
    
    
    
  },
  ],
  exportedComponents: [
    
  ],
  useEssentials: false,
  methods: [
    
  ],
  optionalDeps: {
    
    loadOptionalFlowAPIDeps: async () => (await import(/* webpackChunkName: "flowAPIDeps" */ '../flowAPIDeps')).default,
    asyncMessagesLoader: (locale: string) => import(`/home/builduser/agent00/work/e0ea1f188487681d/members-area/members-account-bm/src/assets/locale/messages_${locale}.json`),
    createBILogger,
  },
});